<template>
  <div class="bg-gray">
    <div class="container-fluid">
      <div class="user-card" style="padding-top: 10px;">
        <div class="row">
          <div class="col-sm-12">
            <button @click="bulkReview" class="btn btn-primary">Bulk Review</button>
          </div>
        </div>
      <div class="row" style="margin-bottom: 20px;" v-for="user in users" :key="user.id">
        <div class="col-sm-4">
          <router-link :to="`/roomie/user/${user.id}`">
            <img class="user-photo" :src="photo(user)" />
            <p>{{ user.first_name }} {{ user.last_name }}</p>
            <br>
          </router-link>
          <span style="color:red;" v-if="user.suspended">
            <br>
            Suspended
          </span>
        </div>
        <div class="col-sm-4">
          <img class="user-photo" :src="user.verificationPhoto" />
          <br><br>
          <button style="margin-left: 10px;" @click="review(user.id)" class="btn btn-primary">Review</button>
          <button style="margin-left: 10px;" @click="rejectId = user.id" class="btn btn-danger">Reject</button>
        </div>
        <div class="col-12">
          <hr> <!-- Horizontal line spanning the full row -->
        </div>
      </div>
      </div>
    </div>
    <reject-modal v-if="rejectId != null" @rejected="reject" @hidden="cancelReject" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { StoreActions } from "./store/actions";
import StoreIndex from "./store/_StoreIndex";
import {StoreState as state} from './store/state'
import RejectModal from "@/components/roomie/verification/RejectModal.vue";

export default {
  name: "ReportedUsers",
  components: {
    RejectModal
  },
  data() {
    return {
      suspendConfirm: false,
      rejectId: null
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    cancelReject() {
      this.rejectId = null
    },
    reject(reason) {
      this.dispatch(StoreActions.reject, {id: this.rejectId, reason: reason})
      this.cancelReject()
    },
    load() {
      this.dispatch(StoreActions.getRecent);
    },
    commit(path, value) {
      this.$store.commit(`${StoreIndex.path}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${StoreIndex.path}/${path}`, value);
    },
    photo(user) {
      if(user.photos.length > 0) {
        return user.photos[0].url;
      }

      return null;
    },
    review(id) {
      this.dispatch(StoreActions.markReviewed, id);
    },
    bulkReview() {
      let ids = this.users.map((it) => {
        return it.id
      });
      this.dispatch(StoreActions.bulkReview, ids);
    },
    needsVerify(id) {
      this.dispatch(StoreActions.needsVerify, id);
    }
  },
  computed: {
    ...mapState(StoreIndex.path,[
      state.users
    ])
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background: #46c3f0;
  color: white;
}
.btn-primary {
  background: #46c3f0 !important;
  border-color: #46c3f0 !important;
}

a {
  color: black;
  font-weight: bold;
}

.btn-secondary {
  background: lightgray;
  border-color: lightgray;
}

@media (max-width: 700px) {
  .mobile-hide {
    display: none;
  }
}

@media (min-width: 701px) {
  .large-hide {
    display: none;
  }
}

.user-card {
  background: white;
  margin: 20px;
  border-radius: 10px;
}

.user-img {
  border-radius: 10px;
  margin: 10px;
}

label {
  color: gray;
  font-weight: bold;
}

.user-photo {
  height:200px;
}

@media (max-width:767px) {
  .user-photo {
    height: 150px;
  }
}
</style>