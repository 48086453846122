// import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/components/login/Login.vue"
import Home from "@/components/home/Home.vue"
import WhiteboardChart from "@/components/whiteboard/chart/Chart.vue"
import WhiteboardMessages from '@/components/whiteboard/messages/Messages.vue'
import WhiteboardUser from '@/components/whiteboard/user/User.vue'
import WhiteboardUsersByLocation from '@/components/whiteboard/usersByLocation/UsersByLocation.vue';

import RoomieUser from '@/components/roomie/user/User.vue'
import RoomieSearchUsers from '@/components/roomie/user/List.vue'
import RoomieReportedUsers from '@/components/roomie/reported/ReportedUsers.vue';
import RoomieRecentUsers from '@/components/roomie/recent/RecentUsers.vue';
import RoomieHousing from '@/components/roomie/housing/Housing.vue';
import RoomieHousingDetail from '@/components/roomie/housing/Detail.vue';
import RoomieCharts from '@/components/roomie/chart/Chart.vue'
import RoomieLocations from '@/components/roomie/locations/Locations.vue'
import RoomieUniversities from '@/components/roomie/universities/Universities.vue'
import RoomieSentiment from '@/components/roomie/sentiment/Sentiment.vue'
import RoomieHousingSentiment from '@/components/roomie/housingSentiment/HousingSentiment.vue'
import RoomieVerify from '@/components/roomie/verification/List.vue'
import RoomieMessages from '@/components/roomie/messages/Messages.vue'
import RoomieMessagesSearch from '@/components/roomie/searchMessages/Messages.vue'
import FlaggedMessages from '@/components/roomie/flaggedMessages/Messages.vue';
import FlaggedGroupMessages from '@/components/roomie/flaggedGroupMessages/Messages.vue';
import BioSentiment from "@/components/roomie/bioSentiment/BioSentiment.vue";
import Subscriptions from "@/components/roomie/subscriptions/Subscriptions.vue";
import FlaggedProfilePictures from "@/components/roomie/profilePictureReview/List.vue";
import FlaggedMessageMedia from "@/components/roomie/messageMediaReview/List.vue";
import FlaggedHousing from "@/components/roomie/flaggedHousing/Listing.vue";
import RecentPurchases from "@/components/roomie/recentPurchases/RecentPurchases.vue";

export default {
    router:new VueRouter({
        routes:[
            { path: '/', component: Login, name:'root' },
            { path: '/login', component: Login, name:'login' },
            { path: '/home', component: Home, name:'home' },
            { path: '/whiteboard/charts', component: WhiteboardChart, name:'WhiteboardChart' },
            { path: '/whiteboard/messages', component: WhiteboardMessages, name:'WhiteboardMessages' },
            { path: '/whiteboard/user/:id', component: WhiteboardUser, name:'WhiteboardUser' },
            { path: '/whiteboard/users/location', component: WhiteboardUsersByLocation, name:'WhiteboardUsersByLocation' },


            { path: '/roomie/user/:id', component: RoomieUser, name:'RoomieUser' },
            { path: '/roomie/conversations/:conversationId/messages', component: RoomieMessages, name:'RoomieMessages' },
            { path: '/roomie/messages', component: RoomieMessagesSearch, name:'RoomieMessagesSearch' },
            { path: '/roomie/messages/flagged', component: FlaggedMessages, name:'FlaggedMessages' },
            { path: '/roomie/messages/group/flagged', component: FlaggedGroupMessages, name:'FlaggedGroupMessages' },
            { path: '/roomie/users/reported', component: RoomieReportedUsers, name:'RoomieReportedUsers' },
            { path: '/roomie/users/recent', component: RoomieRecentUsers, name:'RoomieRecentUsers' },
            { path: '/roomie/users/search', component: RoomieSearchUsers, name:'RoomieSearchUsers' },
            { path: '/roomie/housing', component: RoomieHousing, name:'RoomieHousing' },
            { path: '/roomie/housing/sentiment', component: RoomieHousingSentiment, name:'RoomieHousingSentiment' },
            { path: '/roomie/housing/flagged', component: FlaggedHousing, name:'FlaggedHousing' },
            { path: '/roomie/housing/:id', component: RoomieHousingDetail, name:'RoomieHousingDetail' },
            { path: '/roomie/charts', component: RoomieCharts, name:'RoomieCharts' },
            { path: '/roomie/locations', component: RoomieLocations, name:'RoomieLocations' },
            { path: '/roomie/universities', component: RoomieUniversities, name:'RoomieUniversities' },
            { path: '/roomie/sentiment', component: RoomieSentiment, name:'RoomieSentiment' },
            { path: '/roomie/verify', component: RoomieVerify, name:'RoomieVerify' },
            { path: '/roomie/bioSentiment', component: BioSentiment, name:'BioSentiment' },
            { path: '/roomie/subscriptions', component: Subscriptions, name:'Subscriptions' },
            { path: '/roomie/purchases/recent', component: RecentPurchases, name:'RecentPurchases' },
            { path: '/roomie/flaggedProfilePictures', component: FlaggedProfilePictures, name:'FlaggedProfilePictures' },
            { path: '/roomie/flaggedMessageMedia', component: FlaggedMessageMedia, name:'FlaggedMessageMedia' },
        ]
    })
}