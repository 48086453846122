<template>
  <div class="bg-gray">
    <div class="container-fluid" v-if="user">
      <div class="user-card">
        <div class="row">
          <div class="col-sm-12">
            <img
              class="user-img"
              v-for="photo in user.photos"
              :key="photo.id"
              height="500px"
              :src="photo.url"
            />
          </div>
          <div class="col-sm-12">
            <h3>{{ user.first_name }} {{ user.last_name }}</h3>
          </div>
          <div
            class="col-sm-4"
            v-for="prop in properties"
            :key="prop.value"
          >
            <label>{{ prop.name }}</label>
            <p>{{ user[prop.value] }}</p>
          </div>

          <div class="col-sm-12">
            <h4>Interests</h4>
            <p v-if="user.interests">
              {{
                user.interests
                  .map((it) => {
                    return it.name;
                  })
                  .join(", ")
              }}
            </p>
          </div>

          <div class="col-sm-12">
            <h4>IP Addresses</h4>
            <p v-for="ip in user.ipAddresses" :key="ip.ipAddress">
              {{ ip.ipAddress}} - {{ ip.city }}, {{ ip.region }} ({{ ip.country }}) <span v-if="ip.vpn" style="font-weight:bold">VPN</span>
            </p>
          </div>

          <div class="col-sm-12">
            <h4>Location Changes</h4>
            <p v-for="location in user.locations" :key="location">
              {{ location }}
            </p>
          </div>

          <div class="col-sm-12">
            <h4>University Changes</h4>
            <p v-for="uni in user.universityChanges" :key="uni">
              {{ uni }}
            </p>
          </div>

          <div class="col-sm-12">
            <h4>Housing</h4>
            <router-link v-for="(listing, housingIdx) in user.housing" :key="housingIdx" :to="`/roomie/housing/${listing.id}`">
              {{ listing.name }} - {{ listing.address }} {{ listing.city }} {{ listing.state }} {{ listing.zip }}
            </router-link>
          </div>

          <div class="col-sm-12">
            <h4>Purchases</h4>
            <span v-for="(purchase, purchaseIdx) in user.purchases" :key="purchaseIdx">
              {{ purchase.type }} {{ purchase.ts }} ({{ purchase.source }})
            </span>
          </div>

          <div class="col-sm-6">
            <h4>Reported By</h4>
            <p v-for="u in user.reportedBy" :key="u.id">
              <router-link :to="`/roomie/user/${u.reporterUserId}`">{{
                u.reporterUserId
              }}</router-link>
              {{u.reason}}
            </p>
          </div>

          <div class="col-sm-6">
            <h4>Reported</h4>
            <p v-for="u in user.reportedUsers" :key="u.id">
              <router-link :to="`/roomie/user/${u.reportedUserId}`">{{
                u.reportedUserId
              }}</router-link>
              {{u.reason}}
            </p>
          </div>

          <div class="col-sm-12">
            <h4>Verification Photos</h4>
              <div v-for="(photo, idx) in user.verificationPhotos" :key="idx">
                <img class="user-photo" :src="photo"/>
            </div>
          </div>

          <div class="col-sm-12" style="margin-top: 20px" v-if="user.plus == 0">
            <button @click="grantPro" class="btn btn-primary" id="btn">Grant Pro</button>
          </div>

          <hr>

          <div class="col-sm-12" style="margin-top: 20px" v-if="user.suspended == 0">
            <button @click="suspendClick" class="btn btn-danger" id="btn">Suspend</button>
            <div class="row" v-if="suspendConfirm">
              <div class="col-sm-6" style="background:#efefef; border-radius:15px;margin-left:25%;margin-top:50px;">
                <p>Are you sure you want to suspend this user?</p>
                <button @click="suspendConfirmClicked" class="btn btn-danger" style="margin-right:10px;">
                  Suspend
                </button>
                <button @click="suspendConfirm = false" class="btn btn-default">Cancel</button>
              </div>
            </div>
          </div>

          <div class="col-sm-12" style="margin-top: 20px" v-if="user.needsVerify == 0">
            <button @click="verifyClick" class="btn btn-warning" id="btn">Needs Verified</button>
          </div>

          <div class="col-sm-12" style="margin-top: 20px">
            <button @click="timeoutClick" class="btn btn-warning" id="btn">8 Hour Timeout</button>
          </div>

          <div class="col-sm-12" style="margin-top: 20px">
            <hr />
            <h4>Conversations</h4>
            <table class="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>User 1 ID</th>
                  <th>User 2 ID</th>
                  <th>Message Preview</th>
                  <th>Last Message</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="convo in user.conversations" :key="convo.id">
                  <router-link :to="`/roomie/conversations/${convo.id}/messages`">
                    <td>{{ convo.id }}</td>
                  </router-link>
                  <td>
                    <router-link :to="`/roomie/user/${convo.user1Id}`">
                      {{ convo.user1Id }}
                    </router-link>
                  </td>
                  <td>
                    <router-link :to="`/roomie/user/${convo.user2Id}`">
                      {{ convo.user2Id }}
                    </router-link>
                  </td>
                  <td>{{ convo.message_preview }}</td>
                  <td>{{ convo.last_message }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-sm-12" style="margin-top: 20px">
            <hr />
            <h4>Sent Private Messages</h4>
            <table class="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Conversation ID</th>
                  <th>Message</th>
                  <th>Timestamp</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="convo in user.sentMessages" :key="convo.id">
                  <td>{{ convo.id }}</td>
                  <router-link :to="`/roomie/conversations/${convo.conversationId}/messages`">
                    <td>{{ convo.conversationId }}</td>
                  </router-link>
                  <td>

                    <video
                        v-if="convo.signedUrl && convo.thumbnailUrl"
                        width="320" height="240" controls>
                      <source :src="convo.signedUrl" type="video/mp4">
                    </video>
                    <img
                      class="user-img"
                      v-else-if="convo.signedUrl && !convo.thumbnailUrl"
                      height="200px"
                      :src="convo.signedUrl"
                    />
                    <span v-else>
                    {{ convo.message }}
                    </span>
                    </td>
                    
                  <td>{{ convo.timestamp }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-sm-12" style="margin-top: 20px">
            <hr />
            <h4>Sent Group Message</h4>
            <table class="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Message</th>
                  <th>Timestamp</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="convo in user.sentGroupMessages" :key="convo.id">
                  <td>{{ convo.id }}</td>
                  <td>{{ convo.message }}</td>
                  <td>{{ convo.ts }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { StoreActions } from "./store/actions";
import StoreIndex from "./store/_StoreIndex";
import {StoreState as state} from './store/state'
import { StoreMutations as mutations } from './store/mutations';

export default {
  name: "User",
  components: {
  },
  data() {
    return {
      suspendConfirm: false
    };
  },
  mounted() {
    let id = this.$route.params.id;
    this.commit(mutations.SET_ID, id);
    this.getUser();
  },
  methods: {
    commit(path, value) {
      this.$store.commit(`${StoreIndex.path}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${StoreIndex.path}/${path}`, value);
    },
    getUser() {
        this.dispatch(StoreActions.getUser);
    },
    suspendClick() {
      this.suspendConfirm = true
    },
    suspendConfirmClicked() {
      this.suspendConfirm = false
      this.dispatch(StoreActions.suspend);
    },
    verifyClick() {
      this.dispatch(StoreActions.needsVerify);
    },
    timeoutClick() {
      this.dispatch(StoreActions.timeout);
    },
    grantPro() {
      this.dispatch(StoreActions.grantPro);
    }
  },
  computed: {
    ...mapState(StoreIndex.path,[
      state.user
    ]),
    properties() {
        return [
            {name:"Facebook Id",value:"facebook_id"},
            {name:"Apple Id",value:"apple_id"},
            {name:"Type",value:"type"},
            {name:"Universities",value:"universities"},
            {name:"Major",value:"major"},
            {name:"Latitude",value:"lat"},
            {name:"Longitude",value:"lng"},
            {name:"Distance",value:"distance"},
            {name:"City State",value:"cityState"},
            {name:"Birthday",value:"birthday"},
            {name:"Gender",value:"gender"},
            {name:"Instagram",value:"instagram_link"},
            {name:"Snapchat",value:"snapchat_link"},
            {name:"Twitter",value:"twitter_link"},
            {name:"TikTok",value:"tiktok_link"},
            {name:"Vsco",value:"vsco_link"},
            {name:"Email",value:"email"},
            {name:"Gender Filter",value:"gender_filter"},
            {name:"Invisible",value:"invisible"},
            {name:"Plus",value:"plus"},
            {name:"No Ads",value:"noAds"},
            {name:"Suspended",value:"suspended"},
            {name:"Last Open",value:"lastOpen"},
            {name:"Bio",value:"bio"},
            {name:"Region",value:"region"},
            {name:"Phone Verified",value:"phoneVerified"},
            {name:"Phone Number",value:"phoneNumber"},
            {name: "Subscription", value: "subscription"}
        ]

    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background: #46c3f0;
  color: white;
}
.btn-primary {
  background: #46c3f0 !important;
  border-color: #46c3f0 !important;
}

a {
  color: #46c3f0;
}

.btn-secondary {
  background: lightgray;
  border-color: lightgray;
}

@media (max-width: 700px) {
  .mobile-hide {
    display: none;
  }
}

@media (min-width: 701px) {
  .large-hide {
    display: none;
  }
}

.user-card {
  background: white;
  margin: 20px;
  border-radius: 10px;
}

.user-img {
  border-radius: 10px;
  margin: 10px;
}

label {
  color: gray;
  font-weight: bold;
}



.user-photo {
  width:200px;
  height:200px;
  border-radius: 10px;
  object-fit: cover;
}
</style>